jQuery(function($) {

  // Cart Skeleton Loading
  var skeleton_cart_loading = [`
    <div class="sk-loading">
      <p class="item-book promo">
        <span class="skeleton"></span>
        <span class="skeleton"></span>
      </p> 
      <p class="item-book total">
        <span class="skeleton lg"></span>
        <span class="skeleton"></span>
      </p> 
      <p class="item-book policy-terms">
        <span class="skeleton w-100 mr-0"></span>
      </p>
    </div>`, 
    `<div class="sk-loading">
      <p class="item-book promo">
        <span class="skeleton"></span>
        <span class="skeleton"></span>
      </p> 
      <p class="item-book total">
        <span class="skeleton lg"></span>
        <span class="skeleton"></span>
      </p>
    </div>`
  ];

  // Show Cart Skeleton Loading
  $.fn.show_cart_skeleton = function() {

    if($('.item-book.policy-terms').length == 0) {
  
      $(skeleton_cart_loading[0]).insertAfter('#cart-information .item-book:last');
      $('.cart-information > .promo, .cart-information > .total').addClass('d-none');
    } else {
  
      $(skeleton_cart_loading[1]).insertBefore('#cart-information .item-book.policy-terms:last');
      $('.cart-information > .promo, .cart-information > .total').addClass('d-none');
    }
  };
  
  // Hide Cart Skeleton Loading
  $.fn.hide_cart_skeleton = function() {

    $(skeleton_cart_loading).remove();
    $('.cart-information > .promo, .cart-information > .total, .cart-information > .policy-terms').removeClass('d-none');
  };

  // Room Skeleton Loading
  var room_skeleton = `
  <div class="room skeleton-rooms">
    <div class="item room-img skeleton-img"></div>
    <div class="item room-content">
      <div class="about">
        <span class="w-60 sk-loading"></span>
        <span class="w-40 sk-loading"></span>
        <span class="w-80 mt-4 sk-loading"></span>
        <span class="w-90 sk-loading"></span>
        <span class="w-50 sk-loading"></span>
      </div>
      <div class="room-bottom">
        <span class="w-10 mr-2 sk-loading"></span>
        <span class="w-8 sk-loading"></span>
      </div>
    </div>
  </div>`;

  // Show Room Skeleton Loading
  $.fn.show_room_skeleton = function() {

    if ($('#rooms .room').length != 0) {

      $('.room-type ~ .room').remove();

      for(var i = 0; i < 4; i++) {
	
        $(room_skeleton).insertAfter('.room-type');
      }

    } else {

      var clonedSkeletons = '';
      for (var i = 0; i < 4; i++) {
        clonedSkeletons += room_skeleton;
      }      

      $('#rooms > .text-center').remove();
      $('.cart-information').show();
      $('#rooms').append('<div class="left mt-4">' + shoppingcart_skeleton + '</div><div class="right">' + clonedSkeletons + '</div>');

      $('.no-result-right').remove();
      $('.no-result-left').remove();
    }

  }

  // Hide Room Skeleton Loading
  $.fn.hide_room_skeleton = function() {

    $(room_skeleton).remove();
  }

  //Cart Skeleton Loading
  var shoppingcart_skeleton = `
  <div class="cart-information sticky-cart skeleton-cart cart-skeleton">
    <div class="head">
      <h2 class="destination w-60 sk-loading dark"></h2>
      <span class="w-50 sk-loading dark"></span>
      <span class="sk-loading dark circle"></span>
    </div>
    <p class="item-book d-flex justify-content-between p-0 pl-3 pr-3">
      <span class="w-8 sk-loading"></span>
      <span class="w-10 sk-loading"></span>
    </p>
    <p class="item-book d-flex justify-content-between p-0 pl-3 pr-3">
      <span class="w-10 sk-loading"></span>
      <span class="w-8 sk-loading"></span>
    </p>
    <p class="item-book text-right justify-content-between total p-2">
      <span class="w-30 sk-loading"></span>
      <span class="w-8 sk-loading"></span>
    </p>
    <p class="item-book policy-terms p-2">
      <span class="w-100 sk-loading"></span>
    </p>
    <div class="tail pt-4 pb-4"><span class="w-60 lg sk-loading dark m-auto"></span></div>
  </div>`;

  // Show Cart Skeleton Loading
  $.fn.show_shoppingcart_skeleton = function() {

    if ($('#rooms .room').length != 0) {

      $('#cart-information').hide();      
      $(shoppingcart_skeleton).insertAfter('.currency-exchange');
    }

  }
  
  // Hide Cart Skeleton Loading
  $.fn.hide_shoppingcart_skeleton = function() {

    $('.cart-information').show();
    $(shoppingcart_skeleton).remove();
  };

  // Promo Skeleton Loading
  var promo_loading =`
    <span class="skk-loading"></span>
    <span class="skk-loading"></span>`;

  // Show Promo Skeleton                        
  $.fn.show_promo_skeleton = function() {

		var _this = $('.room-bottom');

		_this.addClass('loading');
		_this.append(promo_loading);
  }

  // Hide Promo Skeleton
  $.fn.hide_promo_skeleton = function() { 

    var _this = $('.room-bottom');

    _this.removeClass('loading');
    _this.find('.skk-loading').remove();
  } 

  // Card Skeleton Loading
  var card_skeleton = `
    <div class="col-6 col-lg-4 cards card-skeleton">
      <div class="card card-rounded">
        <div class="card-img skeleton-img"></div>
        <div class="card-body">
          <span class="w-90 mt-1 mb-1 sk-loading"></span>
          <span class=" w-70 mt-1 mb-1 sk-loading"></span>
          <div class="card-body-flex justify-content-between">
            <span class="w-8 mt-1 mb-1 sk-loading"></span>
            <span class="w-8 mt-1 mb-1 sk-loading"></span>
          </div>
        </div>
      </div>
    </div>`;

  // Show Card Skeleton Loading
  $.fn.show_card_skeleton = function() {

    $('.experiences-list .cards').remove();
    
    for(var i = 0; i < 6; i++) {

      $('.experiences-list .card-list').prepend(card_skeleton);
    }
  }

  // Hide Card Skeleton Loading
  $.fn.hide_card_skeleton = function() {

    $('.experiences-list .cards.card-skeleton').remove();
  }
});