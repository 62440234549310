/* JavaScript File                                                  */
/* event_swipe.js                                                 */
/* Modified March 1, 2023                                             */


var event_swipe = (function(document, window) {

	var evt = [

		function(document, window) {

			let touchStartX = 0;
			let touchStartY = 0;
			let touchEndX = 0;
			let touchEndY = 0;

			var toggle = document.querySelectorAll('[data-swipe="true"]');

			toggle.forEach(function(button){

				button.addEventListener('touchstart', function(event) {
					touchStartX = event.changedTouches[0].screenX;
					touchStartY = event.changedTouches[0].screenY;
					handleSwipe(button);
				});

				button.addEventListener('touchend', function(event) {
					touchEndX = event.changedTouches[0].screenX;
					touchEndY = event.changedTouches[0].screenY;
					handleSwipe(button);
				});

				button.addEventListener('click', function(event) {
					button.classList.add('touch');
				});
			});
			
			function handleSwipe(button) {
				const deltaX = touchEndX - touchStartX;
				const deltaY = touchEndY - touchStartY;
	
				// Determine swipe direction (horizontal swipe)
				if (Math.abs(deltaX) > Math.abs(deltaY)) {
					if (deltaX > 50) {
						button.classList.add('touch');
					} else if (deltaX < -50) {
						button.classList.add('touch');
					}
				} else {
					// Vertical swipe (optional)
					if (deltaY > 50) {
						// alert('Swiped down!');
					} else if (deltaY < -50) {
						// alert('Swiped up!');
					}
				}
			}
			
		}

	],
	initAll = function() {

		initEvt(document, window);
	},
	initEvt = function(document, window) {

		evt.forEach(function(e) {
			
			e(document, window);
		});
	};
	
	return { init: initAll };

})(document, window);

document.addEventListener("DOMContentLoaded", function() {
  event_swipe.init();
});

