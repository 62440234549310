var copy_to_clipboard = (function(document, window) {

	var evt = [

		function(document, window) {

			var toggle = document.querySelectorAll('[data-toggle=copy-to-clipboard]');

			toggle.forEach(function(button){

				var target = document.querySelector('#' + button.getAttribute('aria-controls'));
				var alternate_input = document.querySelector('.alternate-input');

				alternate_input.innerText = target.value;

				var ss = alternate_input.clientWidth + 10;

				target.style.width = ss + 'px';

				button.addEventListener('click', function(){

					target.select();
  				target.setSelectionRange(0, 99999);

					navigator.clipboard.writeText(target.value);

					button.classList.add('copied');
					button.innerHTML = 'link copied';
				});
			});

		}

	],
	initAll = function() {

		initEvt(document, window);
	},
	initEvt = function(document, window) {

		evt.forEach(function(e) {
			
			e(document, window);
		});
	};
	
	return { init: initAll };

})(document, window);

copy_to_clipboard.init();
