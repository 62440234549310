var tab_radio = (function(document, window) {

	var evt = [

		function(document, window) {

			var toggle = document.querySelectorAll('[name=tab-radio]');
			var tabContents = document.querySelectorAll('.tab-radio-content');

			toggle.forEach(function(button){

				var target = document.querySelector('#' + button.getAttribute('aria-controls'));

				button.addEventListener('change', function(){
					toggle.forEach(btn => btn.closest('.nav-tab-item').classList.remove('active'));
					tabContents.forEach(content => content.classList.remove('show'));
					
					button.closest('.nav-tab-item').classList.add('active');
					target.classList.add('show');
				});
			});

		}

	],
	initAll = function() {

		initEvt(document, window);
	},
	initEvt = function(document, window) {

		evt.forEach(function(e) {
			
			e(document, window);
		});
	};
	
	return { init: initAll };

})(document, window);

tab_radio.init();
