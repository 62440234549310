(function( $ ) {


    // Archive promo checker
    $.fn.promoChecker = function() {
        if ($('#rooms .right').length) {
            $('#rooms .room').each(function () {

                var id = $(this).attr('id');

                var startdate = $("#"+ id + " #start-date").attr("value");
                var enddate = $("#"+ id + " #end-date").attr("value");
                var price = $("#"+ id + " #price").attr("value");
                var roomtitle = $("#"+ id + " #room-title").attr("value");
                var currencysym = $("#"+ id + " #currency-symbol").attr("value");
                var currencycode = $("#"+ id + " #currency-code").attr("value");
                var productid = $("#"+ id + " #product-id").attr("value");
                var roomqty = $("#"+ id + " #regular-rate #rooms-available").attr("value");
                var propertyid = $("#propertyid").attr("value");
                var country = $("#country").attr("value");

                $.ajax({
                    type: "POST",
                    url: madmonkey_tickets_options.ajaxurl,
                    data: {
                        action: 'validate_promo',
                        id: id,
                        startdate: startdate,
                        enddate: enddate,
                        price: price,
                        roomtitle: roomtitle,
                        currencysym: currencysym,
                        currencycode: currencycode,
                        productid: productid,
                        roomqty: roomqty,
                        propertyid: propertyid,
                        country: country
                },                
                success: function( response ) {

                    $('body').hide_promo_skeleton();
                    
                    var qtyPromo = $("a[promoqty="+id+"]").attr('qty');                    
                    var qty = $("a[data="+id+"]").attr('qty');

                    if(response) {

                        $("#"+ id + " #regular-rate").hide();
                    } else {

                        $("#"+ id + " #regular-rate").show();
                    }

                    $("#"+ id + " #promo-rate").html(response);                    

                    if(qty && typeof qtyPromo === 'undefined') {

                        $("#"+ id + ' #regular-rate select#qty').val(qty).attr("selected", "selected");

                        $("#"+ id + ' #promo-rate select#qty').val(qty).attr("selected", "selected");
                        $("#"+ id + ' #promo-rate #special-promo select#qty').val(0).attr("selected", "selected");                        
                     
                    } else if (parseInt(qtyPromo) >= 1) {                                             

                        $("#"+ id + ' #regular-rate select#qty').val(qty).attr("selected", "selected");      
                        
                        $("#"+ id + ' #promo-rate select#qty').val(0).attr("selected", "selected");
                        $("#"+ id + ' #promo-rate #special-promo.room-allowed select#qty').val(qtyPromo).attr("selected", "selected");                         

                    }

                    $('a.inclusive-info, #inclusive-info-desc').mouseenter(function() { 
                        $('#inclusive-info-desc', this).show();                        
                      }).mouseleave(function() { 
                        $('#inclusive-info-desc', this).hide();                        
                      }); 

                    return false;
                },
                    error: function(XMLHttpRequest, textStatus, errorThrown) {
                        console.log("Status: " + textStatus);
                        console.log("Error: " + errorThrown);
                    }
                });
            });
        }
    }

    $.fn.checkEmail = function() {
        if ($('#billing_email').length) {
            var email = $("#billing_email").val();

            wp.ajax.post({ action: 'check_email', email}).done(function(response) {
                
                $('#billing_email_field span.woocommerce-input-wrapper').append(response);                

                if(response) {
                    $("input#billing_email").addClass('red');

                    document.getElementById('payment-submit').style.pointerEvents = 'none';

                    $('.shop_table.woocommerce-checkout-review-order-table tfoot').addClass('email-error'); 

                } else {
                    $("input#billing_email").removeClass('red');

                    document.getElementById('payment-submit').style.pointerEvents = 'auto';

                    $('.shop_table.woocommerce-checkout-review-order-table tfoot').removeClass('email-error'); 
                }

                $('span.woocommerce-input-wrapper .login-popup').on('click',   
                    function() {

                        $(".login-modal").modal();
                        $('.popup-login-wrapper').show( "slow" );
                        $('.password-form').hide();

                    }
                );

                $('span.woocommerce-input-wrapper .lost-password').on('click',  
                    function() {

                        $(".login-modal").modal();
                        $('.popup-login-wrapper').hide();
                        $('.password-form').show( "slow" );

                    }
                );

            });
       }
    }

    // rooms checkout popup promo checker
    $.fn.promoCheckerPopup = function() {
        if ($('#rooms-popup-wrapper').length) {
            $('#rooms-popup-wrapper .product').each(function () {

                var id = $(this).attr('id');

                var startdate = $("#"+ id + " #start-date").attr("value");
                var enddate = $("#"+ id + " #end-date").attr("value");
                var price = $("#"+ id + " #price").attr("value");
                var roomtitle = $("#"+ id + " #room-title").attr("value");
                var currencysym = $("#"+ id + " #currency-symbol").attr("value");
                var currencycode = $("#"+ id + " #currency-code").attr("value");
                var productid = $("#"+ id + " #product-id").attr("value");
                var roomqty = $("#"+ id + " #popup-regular-rate #rooms-available").attr("value"); 
                var propertyid = $("#propertyid").attr("value");
                var country = $("#country").attr("value");
                var city = $("#"+ id + " #city").attr("value");

                $.ajax({
                    type: "POST",
                    url: madmonkey_tickets_options.ajaxurl,
                    data: {
                        action: 'validate_promo_popup',
                        id: id,
                        startdate: startdate,
                        enddate: enddate,
                        price: price,
                        roomtitle: roomtitle,
                        currencysym: currencysym,
                        currencycode: currencycode,
                        productid: productid,
                        roomqty: roomqty,
                        propertyid: propertyid,
                        country: country,
                        city: city
                },
                beforeSend: function() {
                    //$("#spinner-ajax").show();
                },
                complete: function() {
                    //$("#spinner-ajax").hide();
                },
                success: function( response ) {

                    var qty = $("a[data="+id+"]").attr('qty');

                    if(response) {

                        $("#"+id+" #popup-regular-rate").hide();
                        console.log('hide regular price!' + "#" + id + " #regular-rate");

                    } else {

                        $("#"+id+" #popup-regular-rate").show();
                        console.log('show regular price!');

                    }

                    $("#"+id+" #popup-promo-rate").html(response);
                    
                    $("#"+id+" .product-info").removeClass('skeleton');
                    $("#"+id+" .product-count").removeClass('skeleton');
                    $("#"+id+" .product-content-footer .select").removeClass('skeleton');

                    if(qty) {
                        $("#"+id+' #popup-regular-rate select#qty').val(qty).attr("selected", "selected");
                        $("#"+id+' #popup-promo-rate select#qty').val(qty).attr("selected", "selected");
                    }

                    return false;
                },
                    error: function(XMLHttpRequest, textStatus, errorThrown) {
                        console.log("Status: " + textStatus);
                        console.log("Error: " + errorThrown);
                    }
                });
            });
        }
    }

    $( document ).ready( function() {
        $('body').promoChecker();                        
        $('body').checkEmail(); 
    });

    $(document).one('click', '.room#show-popup-data', function () {     
        $('body').promoCheckerPopup();
    });

    // hide all skeleton after ajax load
    $( document ).ajaxComplete(function() {
        setTimeout(function(){
            $(".item span").removeClass('skeleton');
            $(".item .cancellation").removeClass('skeleton');
            $(".item .select").removeClass('skeleton');
            $(".item a").removeClass('skeleton');
            $(".item div.room-left").removeClass('skeleton');
            $(".item").removeClass('skeleton');
            $(".count-wrapper").removeClass('skeleton');
        },100); 
    });

})(jQuery);
 
