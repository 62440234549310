(function( $ ) {

// Show Mobile Loading 
function show_mobile_loading(){

	$("#book-now-mobile h2 span").html(" ");
	$("#book-now-mobile h2 span").append("<div class='spinner-border'></div>");
}

// Hide Mobile Loading 
function hide_mobile_loading(){

	$("#book-now-mobile h2 span").html(" ");
}

// regular rate
$(document).on('change', '#regular-rate select#qty', function (e) {

	var limit = $(this).val();
	var id = $(this).find('option:selected').attr('id');

	var button_classes, qty = +$("#" + id + ' #regular-rate .counter').val();
	button_classes = $(e.currentTarget).prop('class');

	var price = $("#" + id + ' #regular-rate #price').val();
	var countryPrice = $("#" + id + ' #regular-rate #country-price').val();
	var currency = $("#" + id + ' #regular-rate #currency-symbol').val();
	var currencyCode = $("#" + id + ' #regular-rate #currency-code').val();
	var country = $("#" + id + ' #country').val();
	var roomTitle = $("#" + id + ' #room-title').val();
	var startDate = $("#" + id + ' #regular-rate #start-date').val();
	var endDate = $("#" + id + ' #regular-rate #end-date').val();
	var child = $("#" + id + ' #child-available').val();
	var adult = $("#" + id + ' #adult-available').val();
	var city = $("#" + id + ' #city').val();
	var qrcode = $('#qrcode').val();
	var roomType = $("#"+ id + ' #room-type').val();

	qty = limit;

	$(".tail").removeClass("active");
	$('.book-now-mobile').removeClass("active");

	$('body').show_cart_skeleton();
	show_mobile_loading();

	wp.ajax.post({ action: 'add_to_cart_booking', id, qty, price, currency, currencyCode, country, roomTitle, startDate, endDate, adult, child, city, countryPrice, qrcode, roomType }).done(function (response) {

		hide_mobile_loading();

		$('#cart-information').html(response);

		var nprice = $("p#total-price").attr("total");
		$(".tail").addClass("active");

		if (nprice != undefined || qty > 0) {

			$("#book-now-mobile h2 span").html(" | " + $("p#total-price").attr("total"));
			$("#book-now-mobile").addClass("active");
			$('#rooms').addClass('active2');
		} else { 

			$("#book-now-mobile h2 span").html(" ");
			$("#book-now-mobile").removeClass("active");
			$(".tail").removeClass("active");
			$("#cart-information").removeClass("active-total");
			$('#rooms').removeClass('active2');
		} 

		$('body').mobileCalendar();

		$('.cart-information').removeClass('active');

		setTimeout(function () {
			jQuery(document).find('.skeleton').each(function() {
				jQuery(this).removeClass('skeleton');
			});
		}, 0);

		$('body').hide_cart_skeleton();

	});
});

//promo rate
$(document).on('change', '#promo-rate .room-allowed:not(#special-promo) select#qty', function (e) {  
 
	var limit = $(this).val();
	var countID = $(this).attr('class');
	var id = $(this).find('option:selected').attr('id');
	var button_classes, qty = +$("#" + id + ' #promo-rate counter-'+id+'-'+countID+' .counter').val();	
	button_classes = $(e.currentTarget).prop('class');

	// reset qty
	$("#" + id + ' #promo-rate .room-allowed#special-promo #qty').val(0);
	$("#" + id + ' #promo-rate #counter-'+id+':not(.counter-'+id+'-'+countID+') #qty').val(0);   

	console.log('#counter-'+id+'-'+countID); 

	var price = $("#" + id + ' #promo-rate .counter-'+id+'-'+countID+' #price').val();
	var countryPrice = $("#" + id + ' #promo-rate .counter-'+id+'-'+countID+' #country-price').val();
	var currency = $("#" + id + ' #promo-rate .counter-'+id+'-'+countID+' #currency-symbol').val();
	var currencyCode = $("#" + id + ' #promo-rate .counter-'+id+'-'+countID+' #currency-code').val();
	var regularPrice = $("#" + id + ' #promo-rate .counter-'+id+'-'+countID+' #regular-price').val();
	var promoName = $("#" + id + ' #promo-rate .counter-'+id+'-'+countID+' #promo-name').val();
	var roomTitle = $("#" + id + ' #room-title').val();
	var startDate = $("#" + id + ' #promo-rate .counter-'+id+'-'+countID+' #start-date').val();
	var endDate = $("#" + id + ' #promo-rate .counter-'+id+'-'+countID+' #end-date').val();
	var promocode = $("#" + id + ' #promo-rate .counter-'+id+'-'+countID+' #promo-code').val();
	var child = $("#" + id + ' #child-available').val();
	var adult = $("#" + id + ' #adult-available').val();
	var city = $("#" + id + ' #city').val();
	var country = $("#" + id + ' #country').val();
	var qrcode = $('#qrcode').val(); 
	var roomType = $("#"+ id + ' #room-type').val();

	console.log('#qrcode-'+qrcode); 

	qty = limit;

	$(".tail").removeClass("active");
	$('.book-now-mobile').removeClass("active");

	$('body').show_cart_skeleton();

	show_mobile_loading();

	wp.ajax.post({ action: 'add_to_cart_booking', id, qty, price, currency, currencyCode, country, roomTitle, startDate, endDate, adult, child, promocode, regularPrice, promoName, city, countryPrice, qrcode, roomType }).done(function (response) {

		hide_mobile_loading();

		$('#cart-information').html(response);

		var nprice = $("p#total-price").attr("total");

		if (nprice != undefined || qty > 0) {

			$("#book-now-mobile h2 span").html(" | " + $("p#total-price").attr("total"));
			$("#book-now-mobile").addClass("active");
			$('#rooms').addClass('active2');
		} else {

			$("#book-now-mobile h2 span").html(" ");
			$("#book-now-mobile").removeClass("active");
			$(".tail").removeClass("active");
			$('#rooms').removeClass('active2');
		}

		$('body').mobileCalendar();

		$('.cart-information').removeClass('active');

		$(document).on('click', "#booking-counntry-cart #submit", function () {
			$('body').mobileSearhCalerdar();
		});

		setTimeout(function () {
			jQuery(document).find('.skeleton').each(function() {
				jQuery(this).removeClass('skeleton');
			});
		}, 0);

		$('body').hide_cart_skeleton();

	});
});

// special-promo
$(document).on('change', '#promo-rate .room-allowed#special-promo select#qty', function (e) {
 
	var limit = $(this).val();
	var id = $(this).find('option:selected').attr('id');
	var button_classes, qty = +$("#" + id + ' #promo-rate #special-promo .counter').val();
	
	// reset qty
	$("#" + id + ' #promo-rate .room-allowed:not(#special-promo) #qty').val(0);	

	button_classes = $(e.currentTarget).prop('class');

	var price = $("#" + id + ' #promo-rate #special-promo #price').val();
	var countryPrice = $("#" + id + ' #promo-rate #special-promo #country-price').val();
	var currency = $("#" + id + ' #promo-rate #special-promo #currency-symbol').val();
	var currencyCode = $("#" + id + ' #promo-rate #special-promo #currency-code').val();
	var regularPrice = $("#" + id + ' #promo-rate #special-promo #regular-price').val();
	var promoName = $("#" + id + ' #promo-rate #special-promo #promo-name').val();
	var country = $("#" + id + ' #special-promo #country').val();
	var roomTitle = $("#" + id + ' #special-promo #room-title').val();
	var startDate = $("#" + id + ' #special-promo #promo-rate #special-promo #start-date').val();
	var endDate = $("#" + id + ' #promo-rate #special-promo #end-date').val();
	var child = $("#" + id + ' #child-available').val();
	var adult = $("#" + id + ' #adult-available').val();
	var promocode = $("#" + id + ' #special-promo #promo-code').val();
	var city = $("#" + id + ' #special-promo #city').val();
	var qrcode = $('#qrcode').val(); 
	var roomType = $("#"+ id + ' #special-promo #room-type').val();
 
	qty = limit;

	$(".tail").removeClass("active");
	$('.book-now-mobile').removeClass("active");

	$('body').show_cart_skeleton();

	show_mobile_loading();

	wp.ajax.post({ action: 'add_to_cart_booking', id, qty, price, currency, currencyCode, country, roomTitle, startDate, endDate, adult, child, promocode, regularPrice, promoName, city, countryPrice, qrcode, roomType }).done(function (response) {

		hide_mobile_loading();

		$('#cart-information').html(response);

		var nprice = $("p#total-price").attr("total");

		if (nprice != undefined || qty > 0) {

			$("#book-now-mobile h2 span").html(" | " + $("p#total-price").attr("total"));
			$("#book-now-mobile").addClass("active");
			$('#rooms').addClass('active2');
		} else {

			$("#book-now-mobile h2 span").html(" ");
			$("#book-now-mobile").removeClass("active");
			$(".tail").removeClass("active");
			$('#rooms').removeClass('active2');
		}

		$('body').mobileCalendar();

		$('.cart-information').removeClass('active');

		$(document).on('click', "#booking-counntry-cart #submit", function () {
			$('body').mobileSearhCalerdar();
		});

		setTimeout(function () {
			jQuery(document).find('.skeleton').each(function() {
				jQuery(this).removeClass('skeleton');
			});
		}, 0);

		$('body').hide_cart_skeleton();

	});

});  

var nprices = $("p#total-price").attr("total");
if(nprices != undefined) {

	$('#rooms').addClass('active2');
} else {
	$('#rooms').removeClass('active2 active');
}

})(jQuery);