(function( $ ) {

function formatState(state) {
	if (!state.id) {
		return state.text;
	}
	var baseUrl = "https://madmonkeyhostels.com/wp-content/themes/madmonkey-tickets/assets/images/icons8-currency.png";

	var $state = $(
		'<span><img class="img-flag" /> <span></span></span>'
	);

	$state.find("span").text(state.text);
	$state.find("img").attr("src", baseUrl);

	return $state;
};

function currency(){

	const $currency = $('.currency');

	$currency.select2({
		templateSelection: formatState,
		minimumResultsForSearch: -1,
		width: '135px',
		dropdownParent: $('.currency-exchange')
	});
}
	
// private or dorm room
$(document).on('click', '#private-room', function () {

	var datecheckin = $("#check-in-alter").val();
	var datecheckout = $("#check-out-alter").val();
	var category = $("#propertyid").val();
	var type = 'private';

	// $("#spinner-ajax").show();
	// $('#rooms').attr('style','visibility: hidden');

	$('body').show_shoppingcart_skeleton();
	$('body').show_room_skeleton();

	wp.ajax.post({ action: 'filter_room', datecheckin, datecheckout, category, type}).done(function(response){

		$('#rooms').html(response);
		$('#rooms').addClass('active');

		$("#spinner-ajax").hide();
		$('#rooms').attr('style','visibility: visible');

		$('.room-type .item a#private-room').show();
		
    $('body').promoChecker();

		$('body').mobileCalendar();

		currency();
		
		$('body').swipeBooking();

		$('body').hide_shoppingcart_skeleton();
		$('body').hide_room_skeleton();

		$('body').show_promo_skeleton();

	});
});

// private or dorm room
$(document).on('click', '#dorm-room', function () {

	var datecheckin = $("#check-in-alter").val();
	var datecheckout = $("#check-out-alter").val();
	var category = $("#propertyid").val();
	var type = 'dorm';

	// $("#spinner-ajax").show();
	
	// $('#rooms').attr('style','visibility: hidden');

	$('body').show_shoppingcart_skeleton();
	$('body').show_room_skeleton();


	wp.ajax.post({ action: 'filter_room', datecheckin, datecheckout, category, type}).done(function(response){

		$('#rooms').html(response);
		$('#rooms').addClass('active');

		$("#spinner-ajax").hide();
		$('#rooms').attr('style','visibility: visible');

		$('.room-type .item a#dorm-room').show();		
 
    $('body').promoChecker();

		$('body').mobileCalendar();

		currency();
		
		$('body').swipeBooking();

		$('body').hide_shoppingcart_skeleton();
		$('body').hide_room_skeleton();

		$('body').show_promo_skeleton();

	});
});
   
})(jQuery);

