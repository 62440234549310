(function( $ ) {

  var skeleton_loading = ['<div class="sk-loading"><p class="item-book promo"><span class="skeleton"></span><span class="skeleton"></span></p> <p class="item-book total"><span class="skeleton lg"></span><span class="skeleton"></span></p> <p class="item-book policy-terms"><span class="skeleton w-100 mr-0"></span></p></div>', '<div class="sk-loading"><p class="item-book promo"><span class="skeleton"></span><span class="skeleton"></span></p> <p class="item-book total"><span class="skeleton lg"></span><span class="skeleton"></span></p></div>', '<div class="sk-loading"><p class="item-book total"><span class="skeleton lg"></span><span class="skeleton"></span></p></div>'];

  // Show Mobile Loading 
  function show_mobile_loading(){

    $("#book-now-mobile h2 span").html(" ");
    $("#book-now-mobile h2 span").append("<div class='spinner-border'></div>");
  }

  // Hide Mobile Loading 
  function hide_mobile_loading(){

    $("#book-now-mobile h2 span").html(" ");
  }

  // add to cart
  $(document).on('click', '.room #book-now', function () {

    var id = $(this).attr("data");

    var qty = $("#"+ id + ' #room-available').val();
    var price = $("#"+ id + ' #price').val();
    var currency = $("#"+ id + ' #currency-symbol').val();
    var currencyCode = $("#"+ id + ' #currency-code').val();
    var country = $("#"+ id + ' #country').val();
    var roomTitle = $("#"+ id + ' #room-title').val();
    var startDate = $("#"+ id + ' #start-date').val();
    var endDate = $("#"+ id + ' #end-date').val();
    var child = $("#"+ id + ' #child-available').val();
    var adult = $("#"+ id + ' #adult-available').val();
    var qrcode = $('#qrcode').val();
    var roomType = $("#"+ id + ' #room-type').val();

    wp.ajax.post({ action: 'add_to_cart_booking', id, qty, price, currency, currencyCode, country, roomTitle, startDate, endDate, adult, child, qrcode, roomType}).done(function(response) {

      $('#cart-information').html(response);

      $('body').mobileCalendar();
      $('body').mobileSearhCalerdar();

    });
  });

  // remove booking on cart
  $(document).on('click', '.cart-information #remove-item', function () {

    var id = $(this).attr("data");
    var city = $(this).attr("city");
    var country = $(this).attr("country");

    $('body').show_cart_skeleton();
    show_mobile_loading();
    $(".tail").removeClass("active");
	  $('.book-now-mobile').removeClass("active");

    $(this).closest('.item-book').addClass('d-none');

    wp.ajax.post({ action: 'remove_item_to_cart', id, country, city}).done(function(response) {

      hide_mobile_loading();

      $('#cart-information').html(response);

      var nprice = $("p#total-price").attr("total");

      if(nprice != undefined && nprice != 0) {
        $("#book-now-mobile h2 span").html(" | " + $("p#total-price").attr("total"));
        $( "#book-now-mobile" ).addClass( "active" );
        $('#rooms').addClass('active2');
      } else {
        $("#book-now-mobile h2 span").html(" ");
        $( "#book-now-mobile" ).removeClass( "active" );
        $( "#cart-information" ).removeClass( "active-total" );
        $('#rooms').removeClass('active active2');
      }

      $('body').mobileCalendar();

      $("#"+ id + ' #regular-rate select#qty').val(0).attr("selected", "selected");
      $("#"+ id + ' #promo-rate select#qty').val(0).attr("selected", "selected");

      $("#cart-information").removeClass('active');

      setTimeout(function () {
        jQuery(document).find('.skeleton').each(function() {
          jQuery(this).removeClass('skeleton');
        });
      }, 0);

      $('body').hide_cart_skeleton();

    });

  });

  // Apply Coupon
  $(document).on('click', 'form#coupon-checkout #apply_coupon', function (e) {

    jQuery('body').trigger('update_checkout');

    var promo = $("form#coupon-checkout #coupon_code").val();
    var form = $("form#coupon-checkout #from-date").val();
    var to = $("form#coupon-checkout #to-date").val();

    wp.ajax.post({ action: 'checkout_apply_promo_code', promo, form, to}).done(function(response) {

      if(response.text == 'success') {

        jQuery('body').trigger('update_checkout');
        // location.reload();

      } else {

        $('.error-mssg-coupon').show();
        $('.error-mssg-coupon span.notice').show().text(response.error);

        e.preventDefault();
      }
    });

    e.preventDefault();
  });


  // Apply Code
  $(document).on('click', 'a#apply-code', function () {

    var promo = $("#promo-code").val();
    var form = $("#from-date").val();
    var to = $("#to-date").val();
    var cityid = $("#propertyid").val();
    var country = $("#country").val();

    $(skeleton_loading[2]).insertBefore('#cart-information .item-book.policy-terms:last');
    $('.cart-information > .total').addClass('d-none');
    $(".tail").removeClass("active");
	  $('.book-now-mobile').removeClass("active");

    show_mobile_loading();

    wp.ajax.post({ action: 'apply_promo_code', promo, form, to, cityid, country}).done(function(response) {

      hide_mobile_loading();

      $('#cart-information').html(response);

      var nprice = $("p#total-price").attr("total");

      if(nprice != undefined && nprice != 0) {
          $("#book-now-mobile h2 span").html(" | " + $("p#total-price").attr("total"));
          $( "#book-now-mobile" ).addClass( "active" );
      } else {
          $("#book-now-mobile h2 span").html(" ");
          $( "#book-now-mobile" ).removeClass( "active" );
      }

      $('body').mobileCalendar();

      $(document).on('click', "#booking-counntry-cart #submit", function () {
        $('body').mobileSearhCalerdar();
      });

      setTimeout(function() {
        $('.notice, .notice-valid').fadeOut('fast');
      }, 8000);

      $("#cart-information").removeClass('active');

      $('body').hide_cart_skeleton();

    });

  });

  // remove promo code on cart
  $(document).on('click', 'a#remove-coupon', function () {

    var target = $(this).attr("action");
    var promo = $(this).attr("data");
    var form = $("#from-date").val();
    var to = $("#to-date").val();
    var cityid = $("#propertyid").val();
    var country = $("#country").val();

    $('body').show_cart_skeleton();
    show_mobile_loading();
    $(".tail").removeClass("active");
	  $('.book-now-mobile').removeClass("active");

    $(this).closest('.item-book').addClass('d-none');

    wp.ajax.post({ action: 'apply_promo_code', promo, form, to, cityid, target, country}).done(function(response) {

      hide_mobile_loading();

      $('#cart-information').html(response);

      var nprice = $("p#total-price").attr("total");

      if(nprice != undefined && nprice != 0) {
          $("#book-now-mobile h2 span").html(" | " + $("p#total-price").attr("total"));
          $( "#book-now-mobile" ).addClass( "active" );
      } else {
          $("#book-now-mobile h2 span").html(" ");
          $( "#book-now-mobile" ).removeClass( "active" );
      }

      $('body').mobileCalendar();

      $(document).on('click', "#booking-counntry-cart #submit", function () {
        $('body').mobileSearhCalerdar();
      });

      setTimeout(function() {
        $('.notice, .notice-valid').fadeOut('fast');
      }, 8000);

      $("#cart-information").removeClass('active');

    });

  });

  // archive search calendar
  $(document).on('click', '#filter-calendar', function () {
    $(".mmkbooking-archive #mmkcalendar-search").slideDown( "slow" );

    $([document.documentElement, document.body]).animate({
      scrollTop: $("#body-top").offset().top
    }, 1000);


    setTimeout(function(){
      $("#datepicker").datepicker('refresh');
      $("#datepicker").attr('style',  'display:inline-flex');
      $('.booking-search #check-out').parents('.item').removeClass('active');

    }, 150);

  });


})(jQuery);
