(function( $ ) {

$("document").ready(function () {

  new_navigation();
	minisearch();
	showcalendar();
  hidecalendar();
});

$(window).scroll(function() {

  new_navigation();
	minisearch();
  minisearchOnScroll();
});

// nav sticky
function new_navigation(){

	if($('.navscroll-big').length) {
		
		var window_height = $(document).scrollTop();

		if($('.entry-header').length){

			var navscroll_position = $('.entry-header').offset().top;

			if(window_height > navscroll_position){

				$('.navscroll-big').addClass('scrolled');
				$('.site-header').addClass('no-shadow');
			} else {
	
				$('.navscroll-big').removeClass('scrolled');
				$('.site-header').removeClass('no-shadow');
			}
		}
	}

	if ($('#navscroll').length) {		

		var window_height = $(document).scrollTop();
		var navscroll_position = $('.entry-wrap').offset().top;

		if (window_height > navscroll_position) {

			$('#navscroll').addClass('scrolled');
		} else {

			$('#navscroll').removeClass('scrolled');

		}
	}

	if($('.nav-track').length) {
		
		var window_height = $(document).scrollTop();

		if($('.section-track').length) {

			$('.nav-track .scroll a').each(function(){
	
				var _this = $(this).attr('href');
				var navTrack = $('.nav-track').outerHeight();
				
				if($(_this).length) {

					var target = $(_this).offset().top - 91;
					var target2 = target - navTrack;

					
					var elementHeight = target2 + $(_this).outerHeight(true);
		
					if(window_height >= target2 && window_height < elementHeight) {
		
						$(this).parent('.scroll').addClass('active');
						
					} else {
		
						$(this).parent('.scroll').removeClass('active');
					}
				}
	
	
			});		
		}
	}

	if($('.pos-sticky').length) {

		var window_height = $(document).scrollTop();

		var selector = $('.pos-sticky').prev();
		var headerHeight = $('.site-header').height();
		var selectorHeight = selector.outerHeight() - headerHeight;

		var navscroll_position = selector.offset().top + selectorHeight;

			if(window_height > navscroll_position){

				$('.pos-sticky').addClass('scrolled');
				$('.site-header').addClass('no-shadow');
			} else {
	
				$('.pos-sticky').removeClass('scrolled');
				$('.site-header').removeClass('no-shadow');
			}
	}
}

// mini search booking
function minisearch(){

	var scrollTop = $(window).scrollTop();
	var searchbooking = $('.booking-search').hasClass("fixed");
	var selector = $('.page-banner, .exp-hero-content');

	var minisearchs = $('.mini-search-header').length;
	
	if(minisearchs){

		var targetScroll = selector.offset().top + selector.outerHeight(true);

		if ( scrollTop > targetScroll && !searchbooking) { 
			
			$('.mini-search-header').addClass('show');
		} else {
			$('.mini-search-header').removeClass('show');
		}
	}
}

// mini search booking on scroll
function minisearchOnScroll(){

  var scrollTop = $(window).scrollTop();
	var selector = $('.page-banner, .exp-hero-content');

	var minisearchs = $('.mini-search-header').length;
	
	if(minisearchs) {
		
		var targetScroll = selector.offset().top + selector.outerHeight(true);

		if ( scrollTop < targetScroll) { 
			
			$('.bookig-search-holder').removeClass('fixed');
			$('.content-destination #navscroll, .navscroll-big').removeClass('adjust');
			$('.navscroll-big').removeClass('adjust');
		} 
	}
}

// show calendar from mini search booking
function showcalendar(){

	$('.mini-search').click(function(){

		$('.bookig-search-holder').addClass('fixed');
		$('.mini-search-header').removeClass('show');

		$('.content-destination #navscroll').addClass('adjust');
		$('.navscroll-big').addClass('adjust');
	});

	$('.col-mini-location').click(function(){
		$('.destination-select').select2('open');
		$('.destination-select').parents('.item').addClass('active');
		$('.booking-search .container').addClass('active');
	});

}

// hide calendar when click outside
function hidecalendar(){

  $(document).on('click',function(e) {
    var container = $(".booking-search, .select2-dropdown, .mini-search");
		var scrollTop = $(window).scrollTop();
		var selector = $('.page-banner, .exp-hero-content');

		var minisearchs = $('.mini-search-header').length;

		if(minisearchs) {
			
			var targetScroll = selector.offset().top + selector.outerHeight(true);

			if (!container.is(e.target) && container.has(e.target).length === 0 && scrollTop > targetScroll) {
				
				$('.bookig-search-holder').removeClass('fixed');
				$('.mini-search-header').addClass('show');
				$('.content-destination #navscroll').removeClass('adjust');
				$('.navscroll-big').removeClass('adjust');
			}
		}

	});
}

})(jQuery);