(function( $ ) {    

    $(document).on('click', '.popup-rooms #submit.desktop, .popup-rooms #submit.mobile', function () { 

        var category = $('#destination option:selected').val();
        var datecheckin = $('#check-in-alter, #mcheck-in-alter').val();
        var datecheckout = $('#check-out-alter, #mcheck-out-alter').val();
        var qrcode = $('#qrcode').val();

        $('#popup-promo-rate .counter').val(0);
        $('#popup-regular-rate .counter').val(0);

        if(category == '41') {

            var win = window.open("https://hotels.cloudbeds.com/reservation/muXJte#checkin=" + datecheckin + "&checkout=" + datecheckout, '_blank');
            win.focus();

            return false;
        }

        if(category == '') {
        $("#destination").addClass("input-error");
        } else { 
        $("#destination").removeClass("input-error");
        }

        if(datecheckin == '') {
            $("#check-in").addClass("input-error");
        } else {
            $("#check-in").removeClass("input-error");
        }

        if(datecheckout == '') {
        $("#check-out").addClass("input-error");
        } else {
        $("#check-out").removeClass("input-error");
        }

        if(datecheckin == '' || datecheckout == '') {
        return false;
        }

        $("#spinner-ajax").show();
        $('#rooms-popup-wrapper').attr('style','visibility: hidden');

        wp.ajax.post({ action: 'calendar_search_popup', category, datecheckin, datecheckout, qrcode}).done(function(response) {

            $(".popup-footer-start b.price").html('0'); 

            $('#rooms-popup-wrapper').html(response);  

            $("#spinner-ajax").hide();
            $('#rooms-popup-wrapper').attr('style','visibility: visible');

            if ($('.right .private').length) {
            $('.room-type .item a#private-room').show();
            }

            if ($('.right .dorm').length) {
            $('.room-type .item a#dorm-room').show();
            }

            $('body').promoCheckerPopup();

            var nprice = $("p#total-price").attr("total");

            if(nprice != undefined) {
            $("#book-now-mobile h2 span").html(" | " + $("p#total-price").attr("total"));
            $( "#book-now-mobile" ).addClass( "active" );
            } else {
            $("#book-now-mobile h2 span").html(" ");
            $( "#book-now-mobile" ).removeClass( "active" ); 
            }

            $('body').mobileCalendar(); 
            

            jQuery('body').trigger('update_checkout');

        });

    });

    // popup promo rate add to cart
    $(document).on('change', '#popup-promo-rate select#qty', function (e) {
        
        var limit = $(this).val();
        var id = $(this).find('option:selected').attr('id');

        var button_classes, qty = +$("#" + id + ' #promo-rate .counter').val();
        button_classes = $(e.currentTarget).prop('class');

        var price = $("#" + id + ' #popup-promo-rate #price').val();
        var promoPrice = $("#" + id + ' #popup-promo-rate #promo-price').val();
        var countryPrice = $("#" + id + ' #popup-promo-rate #country-price').val();
        var currency = $("#" + id + ' #popup-promo-rate #currency-symbol').val();
        var currencyCode = $("#" + id + ' #popup-promo-rate #currency-code').val();
        var regularPrice = $("#" + id + ' #popup-promo-rate #regular-price').val();
        var promoName = $("#" + id + ' #popup-promo-rate #promo-name').val();
        var country = $("#" + id + ' #country').val();
        var roomTitle = $("#" + id + ' #room-title').val();
        var startDate = $("#" + id + ' #popup-promo-rate #start-date').val();
        var endDate = $("#" + id + ' #popup-promo-rate #end-date').val();
        var child = $("#" + id + ' #child-available').val();
        var adult = $("#" + id + ' #adult-available').val();
        var promocode = $("#" + id + ' #popup-promo-rate #promo-code').val(); 
        var city = $("#" + id + ' #city').val();
        var qrcode = $('#qrcode').val();
        var roomType = $("#"+ id + ' #room-type').val();        

        qty = limit;

        $(".popup-panel .popup-footer .popup-footer-start #alert-loading").show();
        $(".popup-panel .popup-footer .popup-footer-start #alert-loading").addClass('d-block');       
        $('.popup-rooms .popup-footer-start span').hide();

        wp.ajax.post({ action: 'add_to_cart_booking_popup', id, qty, price, currency, currencyCode, country, roomTitle, startDate, endDate, adult, child, promocode, regularPrice, promoName, city, countryPrice, qrcode, roomType }).done(function (response) {
        
            $('.popup-rooms .popup-footer-start span').html(response);
            
            $('body').mobileCalendar();

            $(document).on('click', "#booking-counntry-cart #submit", function () {
                $('body').mobileSearhCalerdar();
            });            

            jQuery('body').trigger('update_checkout');

            $(".popup-panel .popup-footer .popup-footer-start #alert-loading").removeClass('d-block');            
            $(".popup-panel .popup-footer .popup-footer-start #alert-loading").hide();
            $('.popup-rooms .popup-footer-start span').show();

            //$("#" + id + ' span#promo-price-rate').html(currencyCode +" "+ (qty*promoPrice));    
        }); 

    });

    // popup regular rate add to cart
    $(document).on('change', '#popup-regular-rate select#qty', function (e) {
        
        var limit = $(this).val();
        var id = $(this).find('option:selected').attr('id');

        var button_classes, qty = +$("#" + id + ' #promo-rate .counter').val();
        button_classes = $(e.currentTarget).prop('class');

        var price = $("#" + id + ' #popup-regular-rate #price').val();
        var countryPrice = $("#" + id + ' #popup-regular-rate #country-price').val();
        var currency = $("#" + id + ' #popup-regular-rate #currency-symbol').val();
        var currencyCode = $("#" + id + ' #popup-regular-rate #currency-code').val();
        var regularPrice = $("#" + id + ' #popup-regular-rate #regular-price').val();
        var promoName = $("#" + id + ' #popup-regular-rate #promo-name').val();
        var country = $("#" + id + ' #country').val();
        var roomTitle = $("#" + id + ' #room-title').val();
        var startDate = $("#" + id + ' #popup-regular-rate #start-date').val();
        var endDate = $("#" + id + ' #popup-regular-rate #end-date').val();
        var child = $("#" + id + ' #child-available').val();
        var adult = $("#" + id + ' #adult-available').val();
        var promocode = $("#" + id + ' #popup-regular #promo-code').val(); 
        var city = $("#" + id + ' #city').val(); 
        var qrcode = $('#qrcode').val();
        var roomType = $("#"+ id + ' #room-type').val();        

        qty = limit;

        $(".popup-panel .popup-footer .popup-footer-start #alert-loading").show();
        $(".popup-panel .popup-footer .popup-footer-start #alert-loading").addClass('d-block');       
        $('.popup-rooms .popup-footer-start span').hide();

        wp.ajax.post({ action: 'add_to_cart_booking_popup', id, qty, price, currency, currencyCode, country, roomTitle, startDate, endDate, adult, child, promocode, regularPrice, promoName, city, countryPrice, qrcode, roomType }).done(function (response) {
        
            $('.popup-rooms .popup-footer-start span').html(response);
            
            $('body').mobileCalendar();

            $(document).on('click', "#booking-counntry-cart #submit", function () {
                $('body').mobileSearhCalerdar();
            });            

            jQuery('body').trigger('update_checkout');

            $(".popup-panel .popup-footer .popup-footer-start #alert-loading").removeClass('d-block');            
            $(".popup-panel .popup-footer .popup-footer-start #alert-loading").hide();
            $('.popup-rooms .popup-footer-start span').show();

        });

    });

    // Custom Select
    function select(){

        $('.cc-select').each(function(){

            var _this = $(this);

            _this.select2({
                minimumResultsForSearch: -1,
                dropdownCssClass: "qty-dropdown",
                dropdownParent: _this.parents('.c-select'),
            });
        });

    }

    $(document).ready(function() {
        select();
    });

})(jQuery);

