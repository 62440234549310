(function( $ ) {

  $(document).ready(function () {

    // Shopping cart Book now enabled/disabled 
    var billers = $('#customer_details .input-text, #billing_country, #age-check');
    billers.keyup(function(){
        
      checkValidate();	
    });
  
    $('#billing_country').on('change',function(){
  
      checkValidate();
    });
  
    $('#age-check').click(function(){
      
      checkValidate();

    });
    
  });
  
  $( document ).ajaxComplete(function() {
    setTimeout(function(){
      checkValidate();
    },800); 
  });

  function validateEmail(email) { 
    var emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return emailRegex.test(email);
  }
  
  function checkValidate() {

    var email = $("#billing_email").val();   

    if ( $('#billing_first_name').val() == '' 
      || $('#billing_last_name').val() == '' 
      || $('#billing_email').val() == '' 
      || $('#billing_country').val() == '' 
      || $('#age-check').prop('checked') != true 
      || !validateEmail(email)) {
        
      $('.shop_table tfoot, .place-order #place_order').addClass('disabled');

    } else {

      $('.shop_table tfoot, .place-order #place_order').removeClass('disabled');

    }
  }
  
})(jQuery);