"use strict";

(function( $ ) {

function formatState(state) {
  if (!state.id) {
    return state.text;
  }

  var url = window.location.origin;

  console.log(url);

  if($('.currency-exchange').hasClass('is-dark')) {

    var baseUrl = url + "/wp-content/themes/madmonkey-tickets/assets/images/icons8-currency-exchange-100-white.png";
  } else {
    var baseUrl = url +  "/wp-content/themes/madmonkey-tickets/assets/images/icons8-currency-exchange-100.png";
  }

  var $state = $(
    '<span><img class="img-flag" width="26" height="26" /> <span></span></span>'
  );

  $state.find("span").text(state.text);
  $state.find("img").attr("src", baseUrl);

  return $state;
};

function currency(){

  const $currency = $('.currency');

  $currency.select2({    
    templateSelection: formatState,
    minimumResultsForSearch: -1,
    width: '135px',
    dropdownParent: $('.currency-exchange')
  }); 
  
}

currency();

$(document).on('change', ".checkout .currency", function () {   
  
  var cymcurren = $(this).val(); 
  
  wp.ajax.post({ action: 'currency_update_checkout', cymcurren}).done(function(response) { 
    jQuery('body').trigger('update_checkout');
  });

});

// To Do: Temporary Currency Converter
$(document).on('change', ".long-passes-currency .currency", function () { 

  var cymcurren = $(this).val();

  window.location.href = cymcurren;

});

$(document).on('change', ".mmkbooking-archive .currency", function () { 

  var category = $('#destination option:selected').val();
	var datecheckin = $('#check-in-alter').val();
	var datecheckout = $('#check-out-alter').val();
  var country = $('#country').val();
  var qrcode = $('#qrcode').val();
  var cymcurren = $(this).val();  

  $('#promo-rate .counter').val(0);
  $('#regular-rate .counter').val(0);

  if(category == '41') {

    var win = window.open("https://hotels.cloudbeds.com/reservation/muXJte#checkin=" + datecheckin + "&checkout=" + datecheckout, '_blank');

    win.focus();

    return false;
  }

	if(category == '') {
	  $("#destination").addClass("input-error");
	} else {
	  $("#destination").removeClass("input-error");
	}

	if(datecheckin == '') {
		$("#check-in").addClass("input-error");
	  } else {
		$("#check-in").removeClass("input-error");
	  }

	if(datecheckout == '') {
	  $("#check-out").addClass("input-error");
	} else {
	  $("#check-out").removeClass("input-error");
	}

	if(datecheckin == '' || datecheckout == '') {
	  return false;
	}
  
	// $("#spinner-ajax").show(); 
	// $('#rooms').attr('style','visibility: hidden');

  $('body').show_room_skeleton();

	$('body').show_shoppingcart_skeleton();

  $('.right h2.destination, .currency-exchange, .right h2.destination + p').addClass('skeleton');
  $('.room-type').addClass('sk-loader');

	wp.ajax.post({ action: 'calendar_search', category, datecheckin, datecheckout, country, qrcode, cymcurren}).done(function(response) {

		$('#rooms').html(response);

    var toggle = document.querySelectorAll('[data-toggle=readmore]');

			if(toggle) {
				toggle.forEach(function(collapse){
	
					var parent = collapse.parentNode;
	
					var target = parent.querySelector('.' + collapse.getAttribute('aria-controls'));
	
					collapse.addEventListener('click', function(event){
						event.preventDefault();
						if(target.classList.contains('show')) {
	
							target.classList.remove('show');
							collapse.innerHTML = 'READ MORE';
						} else {
							target.classList.add('show');
							collapse.innerHTML = 'READ LESS';
						}
					}, false);
				});
			}
    
		// $("#spinner-ajax").hide();
		// $('#rooms').attr('style','visibility: visible');
    
    currency();

    if ($('.right .private').length) {
      $('.room-type .item a#private-room').show();
    }

    if ($('.right .dorm').length) {
      $('.room-type .item a#dorm-room').show();
    }

    $('body').promoChecker();

    var nprice = $("p#total-price").attr("total");

    if(nprice != undefined) {
      $("#book-now-mobile h2 span").html(" | " + $("p#total-price").attr("total"));
      $( "#book-now-mobile" ).addClass( "active" );
    } else {
      $("#book-now-mobile h2 span").html(" ");
      $( "#book-now-mobile" ).removeClass( "active" );
    }

    $('body').mobileCalendar();
    $('body').swipeBooking();

    $('body').hide_shoppingcart_skeleton();
		$('body').hide_room_skeleton();

    $('.right h2.destination, .currency-exchange, .right h2.destination + p').removeClass('skeleton');
    $('.room-type').removeClass('sk-loader');

	});

});

function custom_select(){

  const $custom_selection = $('.customer-select');
  const $parent_selection = $('.custom-select-holder');

  $custom_selection.select2({    
    minimumResultsForSearch: -1,
    width: '100%',
    dropdownParent: $parent_selection
  }); 
  
}

custom_select();

})(jQuery);

