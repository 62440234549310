jQuery(function($) {

  $.fn.swipeBooking = function () {
    var swipers2 = document.querySelectorAll('.swiper-holder-2');
  
    swipers2.forEach(function(slides){
  
      var swipe = slides.querySelector('.swiper');
  
      var perviewSm = swipe.getAttribute('data-sm-perview');
      var perviewMd = swipe.getAttribute('data-md-perview');
      var perviewLg = swipe.getAttribute('data-lg-perview');
      var loop = swipe.getAttribute('data-loop');
      var gap = swipe.getAttribute('data-gap');
      var height = swipe.getAttribute('data-autoheight');
      var pagination = slides.querySelector('.swiper-pagination');
      var effect = swipe.getAttribute('data-effect');
      var delay = swipe.getAttribute('data-delay');
      var autoplay = swipe.getAttribute('data-autoplay');
      var next = slides.querySelector('.swiper-button-next');
      var prev = slides.querySelector('.swiper-button-prev');
      var swipe_slide  = swipe.querySelectorAll('.swiper-slide');
  
      var new_loop = JSON.parse(loop);
      var new_gap = parseFloat(gap);
      var new_height = JSON.parse(height);
      var new_autoplay = JSON.parse(autoplay);
  
      swipe = new Swiper(swipe, {
        spaceBetween: new_gap,
        slidesPerView: perviewSm,
        effect: effect,
        centeredSlides: true,
        speed: 500,
        loop: new_loop,
        autoHeight: new_height,
        autoplay: {
          enabled: new_autoplay,
          delay: delay,
          disableOnInteraction: false,
          pauseOnMouseEnter: false,
        },
        pagination: {
          el: pagination,
          clickable: true,
        },
        navigation: {
          nextEl: next,
          prevEl: prev,
        },
        breakpoints: {
          560: {
            slidesPerView: perviewSm,
          },
          576: {
            slidesPerView: perviewMd,
  
          },
          1024: {
            slidesPerView: perviewLg,
          },
        },
        on: {
          beforeInit() {
            const swipe_slide = this.el.querySelectorAll('.swiper-slide');
            const destination_swiper = document.querySelector('.destination-cards');
				
            if (destination_swiper) {
              this.params.loop = swipe_slide.length > 2;
              this.params.autoplay.enabled = swipe_slide.length > 2;
              this.params.centeredSlides = swipe_slide.length > 2;
            }

            if(swipe_slide.length < 3) {

              swipe.classList.add('no-blur');
              this.el.classList.add('swiper-no-swiping');
            } else {

              slides.querySelector('.swiper').classList.add('blur');
            }
          },
        },
      });
  
      if (swipe_slide.length < perviewSm) {
        
        slides.querySelector('.swiper').classList.add('swiper-no-swiping');
  
        var buttons = slides.querySelectorAll('.swiper-button-icon');
  
        buttons.forEach(function(button){
  
          button.classList.add('swiper-button-hidden');
        });
      }
  
    });
  }

});