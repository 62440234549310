var event_hashtag = (function(document, window) {

    var evt = [

            function(document, window) {

                var button = document.querySelector('.get-direction');

                if (button) {

                    button.addEventListener('click', function() {

                        window.localStorage.setItem('cheat_id', 'cheat-seat');

                    });
                }


                setTimeout(() => {

                    if (localStorage.hasOwnProperty("cheat_id")) {
                        var item = localStorage.getItem("cheat_id");
                        var targetTab = document.querySelector('.nav-pills a[href="#cheat-3"]');
                        var targetElement = document.querySelector("#" + item);
                    
                        // Activate the target tab
                        targetTab.click();
                    
                        // Scroll to the target element smoothly
                        if (targetElement) {
                            window.scroll({
                                top: targetElement.offsetTop,
                                behavior: 'smooth'
                            });
                        }
                    } 
                    // else {
                    //     window.scroll({
                    //         top: 0,
                    //         behavior: 'smooth'
                    //     });
                    // }
                    

                    window.localStorage.removeItem('cheat_id');

                }, 500);


                // bar and restaurant sub menu auto scroll

                var button2 = document.querySelectorAll('.link-scroll');

                if (button2) {

                    button2.forEach(function(button) {

                        var target = button.getAttribute('data-target');

                        button.addEventListener('click', function() {

                            window.localStorage.setItem('target_id', target);

                        });
                    });
                }

                setTimeout(() => {

                    if (localStorage.hasOwnProperty("target_id")) {
                        var item = localStorage.getItem("target_id");
                        var targetTab = document.querySelector('.nav-pills a[href="#cheat-3"]');
                        var targetElement = document.querySelector("#" + item);
                    
                        // Activate the target tab
                        targetTab.click();
                    
                        // Scroll to the target element smoothly
                        if (targetElement) {
                            window.scroll({
                                top: targetElement.offsetTop,
                                behavior: 'smooth'
                            });
                        }
                    } 
                    // else {
                    //     window.scroll({
                    //         top: 0,
                    //         behavior: 'smooth'
                    //     });
                    // }

                    window.localStorage.removeItem('target_id');

                }, 500);

                // Tab and scroll
                var tabscrolls = document.querySelectorAll('[data-toggle="tabscroll"]');

                if (tabscrolls) {

                    tabscrolls.forEach(function(tabscroll) {

                        tabscroll.addEventListener('click', function(event) {

                            event.preventDefault();

                            var target = tabscroll.getAttribute('href');
                            var targetTab = document.querySelector('.nav-pills a[href="'+target+'"]');
                            var targetElement = document.querySelector(tabscroll.getAttribute('aria-controls'));

                            // Activate the target tab
                            targetTab.click();

                            // Temporarily change display to 'block'
                            const originalDisplayStyle = targetElement.style.display;
                            targetElement.style.display = 'block';

                            setTimeout(() => {
                                // Scroll to the target element smoothly
                                if (targetElement) {

                                    // Scroll to the element
                                    targetElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });

                                    // Restore original display style
                                    targetElement.style.display = originalDisplayStyle;
                                }
                            }, 100);

                        });
                    });
                }

            }

        ],
        initAll = function() {

            initEvt(document, window);
        },
        initEvt = function(document, window) {

            evt.forEach(function(e) {

                e(document, window);
            });
        };

    return {
        init: initAll
    };

})(document, window);

document.addEventListener("DOMContentLoaded", function() {
    event_hashtag.init();
});

window.addEventListener("resize", function() {
    event_hashtag.init();
});